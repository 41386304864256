////////////////////////////////////////////////////////////////////////////////
// VARIABLES
// -----------------------------------------------------------------------------

// Global
$global-width: rem-calc(1200);
$grid-row-width: rem-calc(1200);
$grid-row-narrow: $grid-row-width - ($global-padding * 4.5);
$grid-row-tiny: $grid-row-width - ($global-padding * 10);
$grid-row-wide: $grid-row-width + ($global-padding * 4.5);
$global-radius: 1rem;
$global-lineheight: 1.66;
$grid-column-gutter: (
  small: 40px,
  medium: 60px,
);
$column-gutter: rem-calc(30);
$header-height: rem-calc(96);

// Global Colors
$white: rgba(255, 255, 255, 1);
$black: rgba(51, 51, 51, 1);
$grey: rgba(153, 164, 171, 1);
$grey-dark: rgba(64, 88, 101, 1);
$grey-light: rgba(242, 242, 242, 1);
$grey-bg: rgba(244, 246, 247, 1);
$grey-shadow: rgba(187, 194, 199, 1);
$grey-shadow-transparent: rgba(50, 52, 71, .15);
$grey-lighter: rgba(245, 245, 245, 1);
$grey-placeholder: #8f8f8f;
$green: rgba(113, 177, 103, 1);
$blue-dark: #256602;
$yellow: rgba(255, 204, 0, 1);
$yellow-dark: rgba(246, 201, 30, 1);
$teal: #5ea235;
$teal-light: rgba(0, 187, 216, 0.2);

// Theme Colors
$primary-color: $blue-dark;
$secondary-color: $teal;
$body-background: $grey-bg;

//social media colors
$facebook: rgba(70, 101, 172, 1);
$twitter: rgba(5, 159, 245, 1);
$linkedin: rgba(0, 118, 183, 1);
$googleplus: rgba(211, 72, 54, 1);

$foundation-palette: (
  primary: #2199e8,
  secondary: #777,
  success: #5da423,
  warning: #e3b000,
  alert: #c60f13,
);

// Typography
$global-font-size: rem-calc(16);
$body-font-color: $black;
$heading-color: $grey-dark;
$heading-color-alt: $grey-light;
$header-sizes: (
  small: (
    'h1': 28,
    'h2': 21,
    'h3': 19,
    'h4': 17,
    'h5': 16,
    'h6': 15,
  ),
  medium: (
    'h1': 44,
    'h2': 34,
    'h3': 30,
    'h4': 22,
    'h5': 22,
    'h6': 17,
  ),
  large: (
    'h1': 47,
    'h2': 34,
    'h3': 30,
    'h4': 22,
    'h5': 22,
    'h6': 17,
  ),
);

// Buttons
$button-padding: 1rem;
$button-color-alt: $white;
$button-radius: 4rem;

// Forms
$input-transition: box-shadow 300ms, border-color 300ms ease-in-out;
$input-radius: 0;
$input-padding: 1em;
$input-border: 0;
$input-border-focus: 0;
$input-shadow: none;
$input-shadow-focus: 0 2px 5px rgba($grey-light, 0.33);

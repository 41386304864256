.minicart-wrapper {
  padding: 0 !important;
  top: $global-padding / -2 !important;
  margin-bottom: $global-margin * 1.5 !important;

  &.active {
    
    .block-minicart {
      border: 0;
      background-color: $white;
      right: 0;
      color: $grey-dark;
      border-radius: 0;
      font-size: $global-font-size * 0.875;
      box-shadow: 0 0 13px scale-color($black, $alpha: -85%);
    }
  }

  .action.showcart {
    line-height: 1rem;
    padding: ($global-padding / 3) ($global-padding * 0.75);
    display: inline-block;
    position: relative;
    background-color: transparent;
    color: $grey;
    margin-top: $global-margin / 4;

    .counter.qty {
      display: block;
    }
    &:before{
      content: "\f291" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-family: "fontawesome";
      transition: color 300ms ease;
      display: inline-block;
      font-size: 2rem;
      line-height: 1;
      vertical-align: top;
      background: none;
      color: inherit;
      border: none;
      height: auto;
      width: auto;
      text-indent: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .showcart .counter-number {
      right: 8px;
      top: -2px;
    }
  }

  .subtitle {
    display: block;
  }

  .counter-label {
    display: none;
  }

  .product-item-details {
    padding-left: $global-padding;
    margin-top: 0;
    padding-top: 0;
  }

  .block-minicart {

    .action.close {
      height: $global-padding * 2;
      width: $global-padding * 2;
    }

    .actions {
      padding-top: $global-padding;
      padding-bottom: $global-padding / 2;
    }
  }

  .items-total,
  .subtotal {
    padding-top: $global-padding;
    padding-bottom: $global-padding;
  }

  .minicart-items-wrapper {

    .product.actions {
      display: flex;
      flex-direction: column;
      right: $global-padding;
    }

    ol {

      li {

        &:not(:first-child) {
          padding-top: $global-padding;
        }
      }
    }
  }
}

// Account creation page
.customer-account-create {

  .page-main {

    .action.back {
      @include button($reverse: true);
    }
  }
}


// Customer account login form block
.customer-account-login {

  .login-container {

    fieldset {
      @include breakpoint(small only) {
        padding-left: $global-padding;
        padding-right: $global-padding;
      }
    }
  }
}

//Site Breadcrumbs
.breadcrumbs {
  // margin-bottom: $global-margin;
  margin-top: $global-margin;

  ul {
    background-color: $white;
    box-shadow: 3px 4px 5px scale-color($black, $alpha: -94%);
    padding: ($global-padding / 2) ($global-padding * 1.1875);
    overflow: hidden;
    color: $grey;
    margin-bottom: 0;
    margin-left: 0;

    li {
      font-size: 0.8rem;
      padding-left: 0;
      padding-right: 1rem;

      strong {
        font-weight: 500;
      }
    }

    li:not(:first-child) {
      display: inline;

      &::before {
        content: ' > ' !important;
        margin-left: $global-margin / -2;
        margin-right: $global-margin / 2;
        font-weight: 500;
      }
    }

    a {
      color: $grey-dark;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.catalog-product-view { 

  // Main product information / sidebar
  .product.media {

    @include breakpoint(medium) {
      @include grid-column(4.5);
    }

    // .fotorama__nav__shaft {
    //   display: inline-flex;
    // }
  }

  .product-info-main {
    @include breakpoint(medium) {
      @include grid-column(7.5);
    }

    .page-title {
      background: transparent;
      padding: 0;
      color: $grey;
      text-transform: capitalize;
      font-size: $global-font-size * 1.4;
      font-weight: 500;
      margin-bottom: $global-margin * 0.75;
    }

    .product.attribute.overview {
      font-size: $global-font-size;
      color: $grey;
      font-weight: 500;
    }

    .price-final_price {
      color: $secondary-color;
      font-size: $global-font-size * 1.5;
      margin: ($global-margin * 0.75) 0;

      .special-price,
      .old-price {
        display: block;
      }

      .special-price {

        .price-final_price {
          color: $red;
        }
      }

      .old-price {
        text-decoration: line-through;

        .price-final_price {
          color: $grey;
          font-size: $global-font-size * 1.3;
        }
      }
    }

    .product-info-stock-sku {
      font-size: $global-font-size * 0.85;
      margin-bottom: $global-margin / 2;

      .stock {
        font-size: $global-font-size;

        &::before {
          content: 'Availability:';
          color: $grey-dark;
          margin-right: $global-margin / 2;
        }

        &.available {
          color: $green;
        }

        &:not(.available) {
          color: $red;
        }
      }
    }

    .product-social-links {
      margin-bottom: $global-margin;
      border-bottom: 1px solid $grey;
      margin-top: $global-margin;
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
      }

      a {
        color: $grey-dark;
        font-size: $global-font-size * 0.9;
        margin-bottom: $global-margin;

        &.mailto {
          @include icon($position: before, $icon: email, $icon-set: 'fontawesome') {
            color: $grey;
            margin-right: $global-margin / 2;
          };
        }

        &.tocompare {
          @include icon($position: before, $icon: compare, $icon-set: 'fontawesome') {
            color: $grey;
            margin-right: $global-margin / 2;
            text-decoration: none;
          };
        }

        &.action.add {
          @include icon($position: before, $icon: pencil, $icon-set: 'fontawesome') {
            color: $grey;
            margin-right: $global-margin / 4;
            text-decoration: none;
          };
        }

        &.action.towishlist {

          @include icon($position: before, $icon: heart, $icon-set: 'fontawesome') {
            margin-right: $global-margin / 2;
            color: $grey;
          };
        }

        &:hover {
          color: $secondary-color;
        }
      }
    }

    .box-tocart {
      @include grid-row;

      > .fieldset {
        border: none;
        padding-left: 0;
        padding-right: 0;
        border-top: 1px solid $grey;
        background-color: transparent;

        &::after {
          clear: both;
        }

        > .field.qty {

          @include breakpoint(medium) {
            float: left;
          }

          > .label,
          > .control {
            float: left;
            width: 30%;
            display: inline-block;
          }

          > .control {

            input {
              width: 100%;
            }
          }

          > .label {
            color: $grey-dark;
            width: 15%;
            font-size: $global-font-size;
            padding-top: $global-padding / 4;
          }
        }

        > .actions {

          @include breakpoint(medium) {
            clear: none;
            float: right;
          }
        }
      }
    }
  }

  .product-details {

    .product-description {

      .attribute.description {

        > .type {
          color: $secondary-color;
          font-size: $global-font-size * 1.25;
          padding: ($global-padding) ($global-padding * 3.5);
          font-weight: 700;
          text-transform: uppercase;
          background-color: scale-color($secondary-color, $alpha: -80%);
          margin-bottom: $global-margin;
          outline: none;
        }
      }
    }
  }

  .product-reviews {
    position: relative;
    padding-top: $global-padding * 5;

    &:before {
      content: 'Reviews';
      color: $secondary-color;
      font-size: $global-font-size * 1.25;
      padding: ($global-padding) ($global-padding * 3.5);
      font-weight: 700;
      text-transform: uppercase;
      background-color: scale-color($secondary-color, $alpha: -80%);
      margin-bottom: $global-margin;
      outline: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }

    fieldset,
    .fieldset {
      background: transparent;
    }

  }

  // Prodcut Detailed Information
  .product.info.detailed {
    @include grid-column(12);
    margin-top: $global-margin * 2;
    padding-left: 0;
    padding-right: 0;

    .data.item.title {
      color: $secondary-color;
      font-size: $global-font-size * 1.25;
      padding: ($global-padding) ($global-padding * 1.5);
      font-weight: 700;
      text-transform: uppercase;
      background-color: scale-color($secondary-color, $alpha: -80%);
      margin-bottom: $global-margin;
      outline: none;
      cursor: pointer;

      a {
        outline: none;
      }
    }

    .data.item.content {
      background-color: $white;
      padding: $global-padding * 1.25;
      margin-bottom: $global-margin;
    }
  }
}

body.catalog-product-view .page-main .product-add-form .fieldset input {
  height: 3rem;
  border-radius: 4px;
}